import React, { ReactElement } from 'react';
import { Row } from 'react-flexbox-grid';
import styled from 'styled-components';

import { breakpoints, grey, spacing } from '../../styles';
import Content from '../common/Content';
import LanguageSwitcher from './LanguageSwitcher';
import Logo from './Logo';

const StyledHeader = styled.header`
  background-color: ${grey['1']};
  @media only screen and (min-width: ${breakpoints.sm}) {
    padding-bottom: ${spacing['3']};
  }
`;

const Header = (): ReactElement => (
  <StyledHeader>
    <Content>
      <Row between="xs" middle="xs">
        <Logo />
        <LanguageSwitcher />
      </Row>
    </Content>
  </StyledHeader>
);

export default Header;
