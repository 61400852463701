const baseSpacing = 1; // rems

export const spacing = {
  '1': `${baseSpacing * 0.25}rem`,
  '2': `${baseSpacing * 0.5}rem`,
  '3': `${baseSpacing * 0.75}rem`,
  '4': `${baseSpacing * 1}rem`,
  '5': `${baseSpacing * 1.5}rem`,
  '6': `${baseSpacing * 2}rem`,
  '7': `${baseSpacing * 2.5}rem`,
  '8': `${baseSpacing * 3}rem`,
  '9': `${baseSpacing * 4}rem`,
  '10': `${baseSpacing * 5}rem`,
  '11': `${baseSpacing * 6}rem`,
  '12': `${baseSpacing * 8}rem`,
  '13': `${baseSpacing * 10}rem`,
  '14': `${baseSpacing * 12}rem`,
  '15': `${baseSpacing * 14}rem`,
  '16': `${baseSpacing * 16}rem`,
};

export const maxWidth = '60rem';

export const breakpoints = {
  xs: '30rem',
  sm: '48rem',
  md: '64rem',
  lg: '75rem',
};

export const borderRadius = {
  sm: '0.125rem',
  regular: '0.25rem',
  md: '0.375rem',
  lg: '0.5rem',
  xl: '0.75rem',
  ['2xl']: '1rem',
  ['3xl']: '1.5rem',
  full: '9999px',
};
