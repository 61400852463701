import { changeLocale, FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React, { ReactElement } from 'react';
import { Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import { fontColor, fontSize, fontWeight, spacing } from '../../styles';

const SwitchButton = styled.button`
  background-color: transparent;
  font-weight: ${fontWeight['medium']};
  font-size: ${fontSize.xl};
  border: none;
  color: ${fontColor.secondary};
  padding: ${spacing['2']};

  &:hover {
    cursor: pointer;
    color: ${fontColor.primary};
  }
`;

const LanguageSwitcher = (): ReactElement => {
  const intl = useIntl();

  return (
    <Row style={{ margin: 0 }}>
      {intl.locale === 'en' && (
        <SwitchButton onClick={() => changeLocale('fi')}>
          <FormattedMessage id="language.finnish" />
        </SwitchButton>
      )}
      {intl.locale === 'fi' && (
        <SwitchButton onClick={() => changeLocale('en')}>
          <FormattedMessage id="language.english" />
        </SwitchButton>
      )}
    </Row>
  );
};

export default LanguageSwitcher;
