import { grey } from './colors';

export const fontWeight = {
  hairline: 100,
  thin: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

export const lineHeight = {
  none: 0,
  tight: 1.25,
  snug: 1.375,
  regular: 1.5,
  relaxed: 1.625,
  loose: 2,
};

export const fontSize = {
  xs: '0.75rem',
  sm: '0.875rem',
  base: '1rem',
  large: '1.125rem',
  xl: '1.25rem',
  ['2xl']: '1.5rem',
  ['3xl']: '1.875rem',
  ['4xl']: '2.25rem',
  ['5xl']: '3rem',
  ['6xl']: '5rem',
};

export const fontColor = {
  primary: grey['8'],
  secondary: grey['6'],
  teritary: grey['4'],
};
