export const green = {
  '1': 'hsl(165, 67%, 96%)',
  '2': 'hsl(163, 75%, 87%)',
  '3': 'hsl(162, 73%, 74%)',
  '4': 'hsl(164, 70%, 63%)',
  '5': 'hsl(166, 64%, 49%)',
  '6': 'hsl(168, 78%, 41%)',
  '7': 'hsl(170, 91%, 32%)',
  '8': 'hsl(172, 94%, 26%)',
  '9': 'hsl(172, 98%, 20%)',
  '10': 'hsl(176, 100%, 13%)',
};

export const yellow = {
  '1': 'hsl(49, 100%, 96%)',
  '2': 'hsl(48, 100%, 88%)',
  '3': 'hsl(48, 95%, 76%)',
  '4': 'hsl(48, 94%, 68%)',
  '5': 'hsl(44, 92%, 63%)',
  '6': 'hsl(42, 87%, 55%)',
  '7': 'hsl(36, 77%, 49%)',
  '8': 'hsl(29, 80%, 44%)',
  '9': 'hsl(22, 82%, 39%)',
  '10': 'hsl(15, 86%, 30%)',
};

export const red = {
  '1': 'hsl(360, 100%, 95%)',
  '2': 'hsl(360, 100%, 87%)',
  '3': 'hsl(360, 100%, 80%)',
  '4': 'hsl(360, 91%, 69%)',
  '5': 'hsl(360, 83%, 62%)',
  '6': 'hsl(356, 75%, 53%)',
  '7': 'hsl(354, 85%, 44%)',
  '8': 'hsl(352, 90%, 35%)',
  '9': 'hsl(350, 94%, 28%)',
  '10': 'hsl(348, 94%, 20%)',
};

export const purple = {
  '1': 'hsl(262, 61%, 93%)',
  '2': 'hsl(261, 68%, 84%)',
  '3': 'hsl(261, 54%, 68%)',
  '4': 'hsl(261, 47%, 58%)',
  '5': 'hsl(262, 43%, 51%)',
  '6': 'hsl(262, 48%, 46%)',
  '7': 'hsl(262, 60%, 38%)',
  '8': 'hsl(262, 69%, 31%)',
  '9': 'hsl(262, 72%, 25%)',
  '10': 'hsl(263, 85%, 18%)',
};

export const purpleGradient = {
  '1': 'hsl(232, 61%, 93%)',
  '2': 'hsl(231, 68%, 84%)',
  '3': 'hsl(231, 54%, 68%)',
  '4': 'hsl(231, 47%, 58%)',
  '5': 'hsl(232, 43%, 51%)',
  '6': 'hsl(232, 48%, 46%)',
  '7': 'hsl(232, 60%, 38%)',
  '8': 'hsl(232, 69%, 31%)',
  '9': 'hsl(232, 72%, 25%)',
  '10': 'hsl(233, 85%, 18%)',
};

export const grey = {
  '1': 'hsl(210, 36%, 96%)',
  '2': 'hsl(212, 33%, 89%)',
  '3': 'hsl(210, 31%, 80%)',
  '4': 'hsl(211, 27%, 70%)',
  '5': 'hsl(209, 23%, 60%)',
  '6': 'hsl(210, 22%, 49%)',
  '7': 'hsl(209, 28%, 39%)',
  '8': 'hsl(209, 34%, 30%)',
  '9': 'hsl(211, 39%, 23%)',
  '10': 'hsl(209, 61%, 16%)',
};

export const orange = {
  '1': 'hsl(24, 100%, 93%)',
  '2': 'hsl(22, 100%, 85%)',
  '3': 'hsl(20, 100%, 77%)',
  '4': 'hsl(18, 100%, 70%)',
  '5': 'hsl(16, 94%, 61%)',
  '6': 'hsl(14, 89%, 55%)',
  '7': 'hsl(12, 86%, 47%)',
  '8': 'hsl(10, 93%, 40%)',
  '9': 'hsl(8, 92%, 35%)',
  '10': 'hsl(6, 96%, 26%)',
};

export const white = 'white';
