import React, { ReactElement } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import { breakpoints, fontSize, spacing, white } from '../../styles';
import Content from '../common/Content';

const StyledFooter = styled.footer`
  padding: ${spacing['3']};

  background: ${white};
  text-align: center;
  font-size: ${fontSize.xl};
  @media only screen and (min-width: ${breakpoints.sm}) {
    padding: ${spacing['2']} 0;
  }
`;

const Footer = (): ReactElement => {
  return (
    <StyledFooter>
      <Content>
        <Row between="md" center="xs">
          <Col>© {new Date().getFullYear()} Mansour Asunnot Oy</Col>
          <Col>
            <a href={`mailto:${process.env.GATSBY_CUSTOMER_SERVICE_EMAIL}`}>
              {process.env.GATSBY_CUSTOMER_SERVICE_EMAIL}
            </a>
          </Col>
        </Row>
      </Content>
    </StyledFooter>
  );
};

export default Footer;
