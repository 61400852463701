import React, { ReactElement } from 'react';
import { Link } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';

import logoImg from '../../images/icons/logo.svg';

const StyledLogo = styled.img`
  width: 10rem;
  margin: 0;
`;

const Logo = (): ReactElement => {
  return (
    <Link to="/">
      <Row style={{ margin: 0 }} middle="xs">
        <StyledLogo src={logoImg} />
      </Row>
    </Link>
  );
};

export default Logo;
