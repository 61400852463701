import React, { PropsWithChildren, ReactElement } from 'react';

import Header from '../components/navigation/Header';
import './layout.css';
import Footer from '../components/navigation/Footer';
import '../static/fonts/Inter/inter.css';
import '../static/fonts/Gilroy/gilroy.css';

type Props = {
  backgroundColor?: string;
};

const Layout = ({
  children,
  backgroundColor,
}: PropsWithChildren<Props>): ReactElement => {
  return (
    <div style={{ backgroundColor }}>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
